import React, { useState, useEffect, userRef, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PaymentApi } from '../ApiConfig';

const PayoutButton = (props) => {
    const [displayWithdrawals, setDisplayWithdrawals] = useState(false);
    const [preFlightResponse, setPreFlightResponse] = useState(false);
    const [payoutResponse, setPayoutResponse] = useState([]);
    const [paypalEmail, setPaypalEmail] = useState([]);
    const [withdrawalAmount, setWithdrawalAmount] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [progressMessage, setProgressMessage] = useState([]);
    const [localRefresh, setLocalRefresh] = useState([]);

    const payoutResponseRef = useRef(payoutResponse);

    var intervalId;

    const currencyCode = props.currency_code;
    const accountUserId = props.account_user_id;
    const refreshParent = props.refresh;

    var refreshCount = 1;

    useEffect(() => {
        payoutResponseRef.current = payoutResponse;
    }, [payoutResponse]);


    useEffect(() => {
        const checkWithdrawalStatus = async (account_user_id, account_currency_code) => {
            console.log(`Call Withdrawal Preflight userid:${account_user_id} currency:${account_currency_code}`)
            setProgressMessage("");
            setErrorMessage("");
            try {
                const pre_flight_response = await PaymentApi.get(`/payouts/payout-pre-flight?user_id=${account_user_id}&currency_code=${account_currency_code}`);
                if (pre_flight_response.data) {
                    setPreFlightResponse(pre_flight_response.data);
                    setDisplayWithdrawals(true);
                }
            }
            catch (err) {
                console.log(`Unexpected error list account record - ${err}`);
            }
        };
        if (accountUserId && accountUserId.length > 0 && currencyCode && currencyCode.length > 0) {
            checkWithdrawalStatus(accountUserId, currencyCode);
        }
    }, [accountUserId, currencyCode, localRefresh]);

    const make_withdrawal = async (e) => {
        e.preventDefault();
        var messages = [];
        if (!preFlightResponse.can_make_withdrawal)
            messages.push("Withdrawals are not possible");

        if (preFlightResponse.paypal_email.length <= 5)
            messages.push("Paypal email is not valid");

        var amount = convertToNumber(withdrawalAmount);
        if (amount) {
            if (withdrawalAmount <= 0)
                messages.push("Withdrawal amount must be greater than 0!");
            else if (withdrawalAmount > preFlightResponse.max_withdrawal_amount)
                messages.push("Withdrawal amount exceeds the maximium");

        }
        else {
            messages.push("The withdrawal amount is not a number");
        }

        if (messages.length) {
            //Error
            setErrorMessage(messages.join(', '));
            return;
        }

        setErrorMessage("");

        //Looking good
        try {
            setProgressMessage("Requesting Withdrawal ...");
            const withdrawal_request = {
                userId: accountUserId,
                currencyCode: currencyCode,
                amount: amount
            };
            const response = await PaymentApi.post(`/payouts/`, withdrawal_request);
            if (response.data) {
                setPayoutResponse(response.data);
                monitor_withdrawal_status();
            }
        }
        catch (err) {
            const errorDescription = err && err.response && err.response.data && err.response.data.detail ? err.response.data.detail : err.message;
            setErrorMessage(`Payout: Unexpected error UserId: ${accountUserId} Amount: ${amount} Currency: ${currencyCode} - ${errorDescription}`);
            setProgressMessage("");
        }
    }

    const set_paypal_email = async (e) => {
        e.preventDefault();
        try {
            setProgressMessage("Setting Paypal Email ...");
            const pre_flight_response = await PaymentApi.put(`/payouts/set-paypal-email?user_id=${accountUserId}&currency_code=${currencyCode}&paypal_email=${paypalEmail}`);
            if (pre_flight_response.data) {
                setPreFlightResponse(pre_flight_response.data);
                setDisplayWithdrawals(true);
                setProgressMessage("Paypal Email Updated");
            }
        }
        catch (err) {
            setErrorMessage(`Set Paypal Email: Unexpected error UserId: ${accountUserId} email: ${paypalEmail} - ${err && err.response & err.response.data && err.response.data.detail ? err.response.data.detail : err}`);
            setProgressMessage("");
        }
    }

    const monitor_withdrawal_status = () => {
        // Set up interval to poll the service every 10 seconds
        intervalId = setInterval(check_withdrawal_status, 3000);
    }

    const check_withdrawal_status = async () => {
        try {
            const response = await PaymentApi.get(`/payouts/${payoutResponseRef.current.id}`);
            if (response && response.data) {
                const payout_transaction = response.data;
                if (payout_transaction.status === "PENDING" || payout_transaction.status === "PROCESSING") {
                    //Waiting
                    setProgressMessage(`Withdrawal is ${payout_transaction.status} ... Please Wait`);
                }
                else if (payout_transaction.status === "SUCCESS") {
                    //Successful
                    clearInterval(intervalId);
                    setErrorMessage("");
                    setProgressMessage(`Withdrawal ${response.data}`);
                    console.log("Payout Success ... Refresh Parent ...")
                    setWithdrawalAmount("");
                    refreshParent(refreshCount++);
                    setLocalRefresh(refreshCount++);
                }
                else {
                    //Oh dear but probably not great
                    clearInterval(intervalId);
                    setProgressMessage("");
                    setErrorMessage(`You withdrawal has ended with a status of ${payout_transaction.status} - ${payout_transaction.errorMessage} (${payout_transaction.errorCode})`);
                }
            }
        }
        catch (err) {
            console.log(`Unexpected error checking withdrawal status - ${err}`);
        }
    }

    const handleChange = (e) => {
        setWithdrawalAmount(e.target.value);
        setErrorMessage("");
    };

    const handleEmailChange = (e) => {
        setPaypalEmail(e.target.value);
    };

    function convertToNumber(str) {
        try {
            // Using the Number constructor
            const num = Number(str);

            // Check if the result is a valid number
            if (isNaN(num)) {
                throw new Error("Invalid number");
            }

            return num;
        } catch (error) {
            console.error("Error converting string to number:", error.message);
            return null; // Return a fallback value or handle the error appropriately
        }
    }

    return (
        <div id="payoutbutton" class="flex flex-col sm:flex-row sm:space-x-4 max-w-md">

            {
                displayWithdrawals
                    ?
                    preFlightResponse.can_make_withdrawal
                        ?
                        <div class="flex flex-col sm:space-x-4 mb-4 sm:mb-0 border rounded-xl bg-blue-100 p-4">
                            <label for="paypalEmail" class="block text-sm font-medium leading-6 text-gray-900">Paypal Email ({preFlightResponse.paypal_email})</label>
                            <span class="mt-4 sm:mt-0 text-sm">{preFlightResponse.message}</span>
                            <div class="mt-2 flex items-center space-x-2 mb-2">
                                <input type="number" name="withdrawalAmount" value={withdrawalAmount} onChange={(e) => handleChange(e)} placeholder="Amount" required id="withdrawalAmount" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                <Link to="#payout" onClick={(e) => make_withdrawal(e)} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Withdraw</Link>

                            </div>
                            {
                                errorMessage
                                    ?
                                    <span class="mt-4 sm:mt-0 text-xs bg-red-800 text-white p-2">{errorMessage}</span>
                                    :
                                    <></>
                            }
                            {
                                progressMessage
                                    ?
                                    <span class="mt-4 sm:mt-0 text-xs">{progressMessage}</span>
                                    :
                                    null
                            }
                        </div>

                        :
                        !preFlightResponse.paypal_email || preFlightResponse.paypal_email.length === 0
                            ?
                            <div class="flex flex-col sm:space-x-4 mb-4 sm:mb-0 border rounded-xl bg-blue-100 p-4">
                                <label for="paypalEmail" class="block text-sm font-medium leading-6 text-gray-900">Paypal Email</label>
                                <span class="mt-4 sm:mt-0 text-sm">{preFlightResponse.message}</span>
                                <div class="mt-2 flex items-center space-x-2">
                                    <input name="paypalEmail" value={paypalEmail} onChange={(e) => handleEmailChange(e)} placeholder="Paypal email" required id="paypalEmail" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                    <Link to="#setpaypalemail" onClick={(e) => set_paypal_email(e)} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</Link>
                                </div>
                                <span class="mt-4 sm:mt-0 text-xs">{preFlightResponse.paypal_email_required_message}</span>
                            </div>
                            :
                            <></>
                    :
                    <></>
            }
        </div>
    );
};

export default PayoutButton;