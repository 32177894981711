import React, { useEffect, useState } from 'react';
import { ActivityPoolApi, CurrencyApi, UserProfileApi } from '../ApiConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageHolder from './ImageHolder';
import ColorPicker from './ColorPicker';
import { usePrivy, getAccessToken } from '@privy-io/react-auth';
import ActivityPoolRegistrations from './ActivityPoolRegistrations';
import ActivityPoolRewardList from './ActivityPoolRewardList';
import ConfirmActionPopup from './ConfirmActionPopup';
import DisplayErrorMessages from './DisplayErrorMessages';
import DisplayProgressMessage from './DisplayProgressMessage';
import ActivityPoolStatusButton from './ActivityPoolStateButton';
import moment, { duration } from 'moment/moment';
import { HexColorPicker } from 'react-colorful';
import CheckBox from './CheckBox';
import ActivityPoolEntryCodeForm from './ActivityPoolEntryCodeForm';
import AccountListActivityPool from './AccountListActivityPool';
import ActivityPoolRewardCards from './ActivityPoolRewardCards';
import ToggleButton from './ToggleButton';
import Cookies from 'js-cookie'

const ActivityPoolForm = () => {
    const navigate = useNavigate();
    const { activityPoolId, sponsorId } = useParams();
    const [sexSettings, setSexSettings] = useState([]);
    const [genderSettings, setGenderSettings] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const { user } = usePrivy();
    const [tabIndex, setTabIndex] = useState(0);
    const { hash } = window.location
    const [showForm, setShowForm] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [progressMessage, setProgressMessage] = useState();
    const [activityPoolDuration, setActivityPoolDuration] = useState(10);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [refreshActivityPool, setRefreshActivityPool] = useState(0);
    const cautionWhenDeletingStatusList = ['OPEN', 'ACTIVE', 'COMPLETED', 'FINISHED'];
    const [readOnly, setReadOnly] = useState(false);
    const [subjectUserId, setSubjectUserId] = useState(false);
    const [apiRootUrl, setApiRootUrl] = useState("")
    const [initialisationComplete, setInitialisationComplete] = useState(false);

    const addErrorMessage = (newError) => { setErrorMessages([...errorMessages, newError]) };

    var tomorrowStartOfDay = moment().add(1, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    var dayAfterTomorrowStartOfDay = moment().add(2, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');

    const [activityPool, setActivityPool] = useState({
        activityPoolId: '',
        sponsorId: sponsorId ? sponsorId : "",
        authorUserId: user.id,
        activityPoolStatus: 'DRAFT',
        mainColor: '',
        secondaryColor: '',
        backgroundImage: '',
        listImage: '',
        iconImage: '',
        title: '',
        description: '',
        instructions: '',
        currencyCode: '',
        entryFee: 0,
        publicVisibleStartDateTime: tomorrowStartOfDay,
        entriesOpenStartDateTime: tomorrowStartOfDay,
        featuredStartDateTime: dayAfterTomorrowStartOfDay,
        featuredEndDateTime: moment.add,
        startDateTime: tomorrowStartOfDay,
        endDateTime: moment.utc(tomorrowStartOfDay).add(duration, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        minimumAge: '0',
        minParticipants: '0',
        maxParticipants: '1000',
        cheatDays: '0',
        countryCodeRestriction: '',
        zipCodeRestriction: '',
        townRestriction: '',
        sexRestriction: '',
        genderRestriction: '',
        nftEntryTokenCurrencyId: '',
        goalType: 'STEPS',
        goalDescription: '',
        goal: 0,
        locationId: '',
        entryFeeCurrencyCode: '',
        nftPassportName: '',
        rewards: [
            {
                reward_type: "PRIZE_POOL",
                amount: 0,
                currencyCode: "",
                description: ""
            }
        ],
        rewardMechanism: 'EQUAL',
        rewardMaxWinners: 0,
        rewardFixedAmount: 0,
        privatePool: false,
        dataPoolId: null,
        requiresEntryCode: false
    });

    useEffect(() => {
        const apiUrl = Cookies.get('ApApiUrl');
        if (apiUrl) setApiRootUrl(apiUrl);
        setInitialisationComplete(true);
    }, []);

    useEffect(() => {
        if (initialisationComplete && activityPoolId) {
            fetchActivityPool();
            if (hash === "activitypools") setTabIndex(1);
        }
        else {
            calculateAndSetEndDate(activityPoolDuration);
            setShowForm(true);
        }
        fetchSettings();
        const apiUrl = Cookies.get('ApApiUrl');
        if (apiUrl) setApiRootUrl(apiUrl);
    }, [initialisationComplete, activityPoolId, refreshActivityPool, subjectUserId]);

    useEffect(() => {
        if (hash === "#registrations") setTabIndex(1);
        else if (hash === "#rewards") setTabIndex(2);
        else if (hash === "#account") setTabIndex(3);
        else if (hash === "#entrycodes") setTabIndex(4);
        else if (hash === "#rewardcards") setTabIndex(5);
        else setTabIndex(0);
    }, [hash]);

    const setApiRootUrlCookie = (url) => {
        Cookies.set('ApApiUrl', url, { expires: 7 });
        setApiRootUrl(url)
    }

    const fetchActivityPool = async () => {
        setProgressMessage("Fetching Activity Pool");
        try {
            var now = new Date();
            var formattedDateTime = now.toISOString().replace('T', ' ').replace('Z', '');
            console.log(`CTIVITY POOL FORM: Get Activity Pool: ${formattedDateTime}`);

            var apiUrl = `/activity-pools/${activityPoolId}?${subjectUserId ? 'user_id=' + subjectUserId + '&' : ""}embed=HEALTH_DATA`;
            //Check if we want to temporarily override the api url for development purpose
            if (apiRootUrl && apiRootUrl.includes("localhost")) {
                apiUrl = `${apiRootUrl}${apiUrl}`
            }

            const response = await ActivityPoolApi.get(apiUrl);

            now = new Date();
            formattedDateTime = now.toISOString().replace('T', ' ').replace('Z', '');
            console.log(`ACTIVITY POOL FORM: Got Activity Pool: ${formattedDateTime}`);

            var ap = response.data;
            setActivityPool(ap);
            if (ap.activityPoolStatus === "OPEN" || ap.activityPoolStatus === "ACTIVE" || ap.activityPoolStatus === "COMPLETED" || ap.activityPoolStatus === "FINISHED") {
                setReadOnly(true);
            }
            var duration = calculateDayDifference(ap.startDateTime, ap.endDateTime) + 1;
            setActivityPoolDuration(duration);
            setShowForm(true);
            setProgressMessage(null);
        }
        catch (err) {
            addErrorMessage(err.message);
        }

    };

    const handleRequiresEntryCodeChange = (value) => {
        setActivityPool({ ...activityPool, "requiresEntryCode": value });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "apDuration") {
            setActivityPoolDuration(value);
            calculateAndSetEndDate(value);
        }
        else {
            setActivityPool({ ...activityPool, [name]: value });
        }
    };

    const handleTabChange = (e) => {
        setTabIndex(Number(e.target.value));
    }

    const handlePrivatePoolToggle = (value) => {
        setActivityPool({ ...activityPool, "privatePool": value });
    }


    const calculateAndSetEndDate = (duration) => {
        var calcDuration = duration > 0 ? duration - 1 : 0;
        var endDate = moment.utc(activityPool.startDateTime).add(calcDuration, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        setActivityPool({ ...activityPool, endDateTime: endDate });
    };

    const setActivityPoolStatus = (state) => {
        setActivityPool({ ...activityPool, activityPoolStatus: state });
    }


    const deleteActivityPool = async (e) => {
        e.preventDefault();
        setShowDeletePopup(true);
    };

    const cancelActivityPool = async (e) => {
        e.preventDefault();
        setShowCancelPopup(true);
    };

    const handleDeletePopupResponse = async (e, value) => {
        e.preventDefault();
        setShowDeletePopup(false);
        if (value) {
            try {
                //Delete AP
                setProgressMessage("Deleting Activity Pool ...");
                var apiUrl = `/activity-pools/${activityPoolId}`;
                //Check if we want to temporarily override the api url for development purpose
                if (apiRootUrl && apiRootUrl.includes("localhost")) {
                    apiUrl = `${apiRootUrl}${apiUrl}`
                }
                await ActivityPoolApi.delete(apiUrl);
                navigate("/activitypools");
            }
            catch (err) {
                setProgressMessage(err.response && err.response.data && err.response.data.detail ? err.response.data.detail : `Unexpected error deleting pool ${JSON.stringify(err)}`);
            }
        }
    }

    const handleCancelPopupResponse = async (e, value) => {
        e.preventDefault();
        setShowCancelPopup(false);
        if (value) {
            try {
                //Cancel AP
                setProgressMessage("Cancelling Activity Pool ...");
                var apiUrl = `/activity-pools/cancel/${activityPoolId}`;
                //Check if we want to temporarily override the api url for development purpose
                if (apiRootUrl && apiRootUrl.includes("localhost")) {
                    apiUrl = `${apiRootUrl}${apiUrl}`
                }
                await ActivityPoolApi.post(apiUrl);
                navigate("/activitypools");
            }
            catch (err) {
                setProgressMessage(err.response && err.response.data && err.response.data.detail ? err.response.data.detail : `Unexpected error cancelling pool ${JSON.stringify(err)}`);
            }
        }
    }

    const fetchSettings = async () => {
        try {
            const currencyResponse = await CurrencyApi.get(`/currency/`);
            setCurrencies(currencyResponse.data.data);
            const sexResponse = await UserProfileApi.get(`/metadata/biological-sex`);
            setSexSettings(sexResponse.data);
            const genderResponse = await UserProfileApi.get(`/metadata/genders`);
            setGenderSettings(genderResponse.data);
            //TODO SET COUNTRIES
        }
        catch (err) {
            addErrorMessage(err.message);
        }

    }

    const calculateDayDifference = (startDateTime, endDateTime) => {
        const firstDate = moment.utc(startDateTime);
        const secondDate = moment.utc(endDateTime);
        const diffInDays = secondDate.diff(firstDate, 'days');
        return diffInDays;
    }

    const setAPMainImageUrl = (url) => {
        setActivityPool((prevState) => ({ ...prevState, backgroundImage: url }));
    }

    const setIconImageUrl = (url) => {
        setActivityPool((prevState) => ({ ...prevState, iconImage: url }));
    }

    const setMainColor = (color) => {
        setActivityPool({ ...activityPool, mainColor: color });
    }

    const setSecondaryColor = (color) => {
        setActivityPool({ ...activityPool, secondaryColor: color });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessages([]);
        try {
            if (activityPoolId) {
                setProgressMessage("Updating ...");
                var apiUrl = `/activity-pools/${activityPool.activityPoolId}`;
                //Check if we want to temporarily override the api url for development purpose
                if (apiRootUrl && apiRootUrl.includes("localhost")) {
                    apiUrl = `${apiRootUrl}${apiUrl}`
                }

                var response = await ActivityPoolApi.put(apiUrl, activityPool);
                setProgressMessage("Activity Pool Update Complete");
            } else {
                setProgressMessage("Creating New ...");
                //Update Rewards Array
                activityPool.rewards[0].currencyCode = activityPool.currencyCode;
                activityPool.rewards[0].description = "Participant Prize Pool";
                activityPool.rewards[0].amount = 0;

                apiUrl = '/activity-pools/';
                //Check if we want to temporarily override the api url for development purpose
                if (apiRootUrl && apiRootUrl.includes("localhost")) {
                    apiUrl = `${apiRootUrl}${apiUrl}`
                }

                var response = await ActivityPoolApi.post(apiUrl, activityPool);
                setProgressMessage("New Activity Pool Created");
                navigate(`/activitypool/${response.data.activityPoolId}`);
            }
            //navigate(-1);
        }
        catch (err) {
            addErrorMessage(err.message);
            if (err.response && err.response.data) {
                addErrorMessage(err.response.data.title);
                var errors = err.response.data.errors;
                addErrorMessage(JSON.stringify(err.response.data));
            }
        }
    };

    const handleRefreshApClick = (event) => {
        event.preventDefault();
        setRefreshActivityPool(refreshActivityPool + 1);
    };

    const clearSubjectUserId = (event) => {
        event.preventDefault();
        setSubjectUserId(null);
    };

    //Check if we are allowed to change the currency code
    const canChangeCurrencyCode = (activityPool) => {

        if (activityPool && activityPool.rewardData) {
            // Check if there are any reward records with a value > 0
            for (const reward of activityPool.rewardData) {
                if (reward.amount > 0) {
                    return false;
                }
            }

        }
        return true;
    };

    return (
        !showForm
            ?
            <section>Please Wait ...</section>
            :
            <div>
                <div>
                    <div>
                        {subjectUserId ? (
                            <div className="text-blue-700 p-2 flex items-center">
                                <p className="text-sm mr-2">Subject User Id: {subjectUserId}</p>
                                <Link
                                    onClick={(e) => clearSubjectUserId(e)}
                                    to="#clearSubject"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Reset to me
                                </Link>
                            </div>
                        ) : null}
                        {
                            window.location.href.includes("localhost")
                                ?
                                <div class="flex items-center mb-4 mt-4">
                                    <span class="mr-2">API Url:</span>
                                    <ToggleButton initial_value={apiRootUrl} label_default_value="https://activity-pool-api.healthblocks.ai" label_alternate_value="http://localhost:8000" set_value={setApiRootUrlCookie} />
                                </div>
                                :
                                null

                        }
                    </div>

                    {
                        activityPoolId
                            ?
                            <div>
                                <div class="sm:hidden">
                                    <label for="tabs" class="sr-only">Select a tab</label>
                                    <select id="tabs" name="tabs" onChange={handleTabChange} value={tabIndex} class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                        <option value="0">Activity Pool Details</option>
                                        <option value="1">Registrations</option>
                                        <option value="2">Rewards</option>
                                        <option value="3">Account</option>
                                        {
                                            activityPool.requiresEntryCode
                                                ?
                                                <option value="4">Entry Codes</option>
                                                :
                                                null
                                        }
                                        <option value="5">Reward Cards</option>
                                    </select>
                                </div>
                                <div class="hidden sm:block">
                                    <div class="border-b border-gray-200">
                                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                            <Link onClick={() => setTabIndex(0)} to="#activitypooldetails" class={tabIndex === 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Activity Pool Details</Link>
                                            <Link onClick={() => setTabIndex(1)} to="#registrations" class={tabIndex === 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Registrations</Link>
                                            <Link onClick={() => setTabIndex(2)} to="#rewards" class={tabIndex === 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Rewards</Link>
                                            <Link onClick={() => setTabIndex(3)} to="#account" class={tabIndex === 3 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Account</Link>
                                            {
                                                activityPool.requiresEntryCode
                                                    ?
                                                    <Link onClick={() => setTabIndex(4)} to="#entrycodes" class={tabIndex === 4 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Entry Codes</Link>
                                                    :
                                                    null
                                            }
                                            <Link onClick={() => setTabIndex(5)} to="#rewardcards" class={tabIndex === 5 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Reward Cards</Link>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                <section id="content">
                    {tabIndex === 0
                        ?
                        <form onSubmit={handleSubmit} class="mt-4">
                            <div class="space-y-12">
                                <div class="border-b border-gray-900/10 pb-12">
                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                        <Link onClick={handleRefreshApClick} to="#refresh" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Refresh</Link>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7">Activity Pool - ({activityPool.activityPoolId === "" ? 'New' : activityPool.activityPoolId})</h2>
                                        </div>

                                        {
                                            activityPool.sponsorId !== ''
                                                ?
                                                <div class="sm:col-span-6 bg-black text-white px-2">
                                                    <h2 class="text-base font-semibold leading-7 text-white-900">Sponsored By {activityPool.sponsorId}</h2>
                                                </div>
                                                :
                                                ''
                                        }


                                        <div class="sm:col-span-3">
                                            <label for="activityPoolStatus" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                                            <div class="mt-2">
                                                {/*Manually Set by Admins - DRAFT, REVIEW - For Review, PUBLISHED - Viewable by the public*/}
                                                {/*Automatically Set OPEN - Open for entries, ACTIVE - In-Progress, FINISHED - Activity Pool Ended, COMPLETED - Pize Money Paid Out */}
                                                <span class="mr-4">{activityPool.activityPoolStatus}</span><ActivityPoolStatusButton activityPool={activityPool} setActivityPoolStatus={setActivityPoolStatus} />
                                            </div>
                                        </div>

                                        <div class="sm:col-span-3">
                                            <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                            <div class="mt-2">
                                                <input type="text" name="title" value={activityPool.title} onChange={handleChange} placeholder="title" required id="title" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6">
                                            <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                            <div class="mt-2">
                                                <textarea type="text" rows="5" name="description" value={activityPool.description} onChange={handleChange} placeholder="description" required id="description" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="goal" class="block text-sm font-medium leading-6 text-gray-900">Goal</label>
                                            <div class="mt-2">
                                                <input type="text" name="goal" value={activityPool.goal} onChange={handleChange} placeholder="Goal" required id="goal" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="goalType" class="block text-sm font-medium leading-6 text-gray-900">Goal Type (Daily)</label>
                                            <div class="mt-2">
                                                <select id="goalType" name="goalType" required value={activityPool.goalType} onChange={handleChange} placeholder="Goal Type" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option></option>
                                                    <option>BREATHING</option>
                                                    <option>RUNNING</option>
                                                    <option>STEPS</option>
                                                    <option>WALKING</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="cheatDays" class="block text-sm font-medium leading-6 text-gray-900">Cheat Days</label>
                                            <div class="mt-2">
                                                <input type="text" name="cheatDays" value={activityPool.cheatDays} onChange={handleChange} placeholder="Cheat Days" required id="cheatDays" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6">
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="minParticipants" class="block text-sm font-medium leading-6 text-gray-900">Min Participants</label>
                                            <div class="mt-2">
                                                <input type="text" name="minParticipants" value={activityPool.minParticipants} onChange={handleChange} placeholder="Min Participants" required id="minParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <div class="mt-2">
                                                <CheckBox label="Require Entry Codes?" description="Entries will be restricted to users having unique codes" initial_value={activityPool.requiresEntryCode} onChange={handleRequiresEntryCodeChange} />
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="maxParticipants" class="block text-sm font-medium leading-6 text-gray-900">Max Participants</label>
                                            <div class="mt-2">
                                                {
                                                    !activityPool.requiresEntryCode
                                                        ?
                                                        <input type="text" name="maxParticipants" value={activityPool.maxParticipants} onChange={handleChange} placeholder="Max Participants" required id="maxParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                        :
                                                        <span>{activityPool.maxParticipants}</span>
                                                }
                                            </div>
                                        </div>


                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Entry Fees and Reward Type</h2>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="rewardMechanism" class="block text-sm font-medium leading-6 text-gray-900">Reward Type</label>
                                            <div class="mt-2">
                                                <select id="rewardMechanism" name="rewardMechanism" required value={activityPool.rewardMechanism} onChange={handleChange} placeholder="Reward Type" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>EQUAL</option>
                                                    <option>LOTTERY</option>
                                                    <option>FIXED</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="privatePool" class="block text-sm font-medium leading-6 text-gray-900">Private Pool</label>
                                            <div class="mt-2">
                                                <ToggleButton initial_value={activityPool.privatePool ? "true" : "false"} label_default_value="false" label_alternate_value="true" set_value={handlePrivatePoolToggle} />
                                            </div>
                                        </div>

                                        {
                                            activityPool.rewardMechanism === 'LOTTERY'
                                                ?
                                                <div class="sm:col-span-1">
                                                    <label for="rewardMaxWinners" class="block text-sm font-medium leading-6 text-gray-900">Max Winners</label>
                                                    <div class="mt-2">
                                                        <input type="text" name="rewardMaxWinners" value={activityPool.rewardMaxWinners} onChange={handleChange} placeholder="Max Winners" required id="rewardMaxWinners" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                </div>
                                                :
                                                activityPool.rewardMechanism === 'FIXED'
                                                    ?
                                                    <div class="sm:col-span-1">
                                                        <label for="rewardFixedAmount" class="block text-sm font-medium leading-6 text-gray-900">Fixed Prize Amount</label>
                                                        <div class="mt-2">
                                                            <input type="text" name="rewardFixedAmount" value={activityPool.rewardFixedAmount} onChange={handleChange} placeholder="Fixed Prize Amount" required id="rewardFixedAmount" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                        }

                                        <div class="sm:col-span-6">
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="entryFee" class="block text-sm font-medium leading-6 text-gray-900">Entry Fee</label>
                                            <div class="mt-2">
                                                <input type="text" name="entryFee" value={activityPool.entryFee} onChange={handleChange} placeholder="Entry Fee" required id="entryFee" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="currencyCode" class="block text-sm font-medium leading-6 text-gray-900">Currency</label>
                                            <div class="mt-2">
                                                {
                                                    canChangeCurrencyCode(activityPool)
                                                        ?
                                                        <select id="currencyCode" name="currencyCode" required value={activityPool.currencyCode} onChange={handleChange} placeholder="Currency" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option></option>
                                                            {currencies
                                                                .map(currency => (
                                                                    <option>{currency.currencyCode}</option>
                                                                ))}
                                                        </select>
                                                        :
                                                        <span>{activityPool.currencyCode} (<span className='text-xs'>Read-Only because there are existing reward records with a value greater than 0!</span>)</span>
                                                }
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Visible Dates</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="publicVisibleStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Publicly Visible</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="publicVisibleStartDateTime" value={activityPool.publicVisibleStartDateTime ? activityPool.publicVisibleStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Public Visible" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="entriesOpenStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Entries Open</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="entriesOpenStartDateTime" value={activityPool.entriesOpenStartDateTime ? activityPool.entriesOpenStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Entries Open" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Activity Dates</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="startDateTime" class="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="startDateTime" value={activityPool.startDateTime ? activityPool.startDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Start Date" id="startDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="apDuration" class="block text-sm font-medium leading-6 text-gray-900">Duration (Days)</label>
                                            <div class="mt-2">
                                                <input type="text" name="apDuration" value={activityPoolDuration} onChange={handleChange} placeholder="Duration (Days)" required id="Duration" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="endDateTime" class="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                                            <div class="mt-4 text-sm text-gray-600">
                                                <label>{moment.utc(activityPool.endDateTime).format("DD/MM/YYYY HH:mm")}</label>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Feature Activity Pool</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="featuredStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured From</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="featuredStartDateTime" value={activityPool.featuredStartDateTime ? activityPool.featuredStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured From" id="featuredStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="featuredEndDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured To</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="featuredEndDateTime" value={activityPool.featuredEndDateTime ? activityPool.featuredEndDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured To" id="featuredEndDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Entry Restrictions</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="minimumAge" class="block text-sm font-medium leading-6 text-gray-900">Minimum Age</label>
                                            <div class="mt-2">
                                                <input type="text" name="minimumAge" value={activityPool.minimumAge} onChange={handleChange} placeholder="Minimum Age" id="minimumAge" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="townRestriction" class="block text-sm font-medium leading-6 text-gray-900">Town Restriction</label>
                                            <div class="mt-2">
                                                <input type="text" name="townRestriction" value={activityPool.townRestriction} onChange={handleChange} placeholder="Town Restriction" id="townRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="zipCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Zipcode Restriction</label>
                                            <div class="mt-2">
                                                <input type="text" name="zipCodeRestriction" value={activityPool.zipCodeRestriction} onChange={handleChange} placeholder="Zip/Postcode Restriction" id="zipCodeRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="sexRestriction" class="block text-sm font-medium leading-6 text-gray-900">Sex Restriction</label>
                                            <div class="mt-2">
                                                <select id="sexRestriction" name="sexRestriction" value={activityPool.sexRestriction} onChange={handleChange} placeholder="Sex Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {sexSettings
                                                        .map(sexSetting => (
                                                            <option>{sexSetting}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="genderRestriction" class="block text-sm font-medium leading-6 text-gray-900">Gender Restriction</label>
                                            <div class="mt-2">
                                                <select id="genderRestriction" name="genderRestriction" value={activityPool.genderRestriction} onChange={handleChange} placeholder="Gender Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {genderSettings
                                                        .map(genderSetting => (
                                                            <option>{genderSetting}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="countryCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Country Restriction</label>
                                            <div class="mt-2">
                                                <select id="countryCodeRestriction" name="countryCodeRestriction" value={activityPool.countryCodeRestriction} onChange={handleChange} placeholder="Country Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {countries
                                                        .map(country => (
                                                            <option>{country}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            activityPool.activityPoolId
                                                ?
                                                <div class="sm:col-span-6 bg-black text-white px-2">
                                                    <h2 class="text-base font-semibold leading-7 text-white-900">Colours and Images</h2>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {

                                            activityPool.activityPoolId
                                                ?
                                                <div class="sm:col-span-3">
                                                    <ImageHolder imagetitle="Main Image" imagetext={activityPool.title ? activityPool.title : "Activity Pool Main Image"} imagetextcolor={activityPool.secondaryColor} setimageurl={setAPMainImageUrl} imageurl={activityPool.backgroundImage} />
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            activityPool.activityPoolId
                                                ?
                                                <div class="sm:col-span-3">
                                                    <ImageHolder imagetitle="Icon" imagetext="Activity Pool Icon" imagetextcolor={activityPool.secondaryColor} setimageurl={setIconImageUrl} imageurl={activityPool.iconImage} />
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            activityPool.activityPoolId
                                                ?

                                                <div class="sm:col-span-2">
                                                    <label for="mainColor" class="block text-sm font-medium leading-6 text-gray-900">Main Colour</label>
                                                    <div class="mt-2">
                                                        <input type="text" name="mainColor" value={activityPool.mainColor} onChange={handleChange} placeholder="Main Color" id="mainColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                    <div class="mt-2">
                                                        <HexColorPicker color={activityPool.mainColor} onChange={setMainColor} />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            activityPool.activityPoolId
                                                ?
                                                <div class="sm:col-span-2">
                                                    <label for="secondaryColor" class="block text-sm font-medium leading-6 text-gray-900">Secondary / AP Title Text</label>
                                                    <div class="mt-2">
                                                        <input type="text" name="secondaryColor" value={activityPool.secondaryColor} onChange={handleChange} placeholder="Secondary Color" id="secondaryColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                                    </div>
                                                    <div class="mt-2">
                                                        <HexColorPicker color={activityPool.secondaryColor} onChange={setSecondaryColor} />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>

                            </div>

                            {
                                true //!readOnly
                                    ?
                                    <div>
                                        <DisplayProgressMessage progressMessage={progressMessage} />
                                        <DisplayErrorMessages errorMessages={errorMessages} />
                                        <ConfirmActionPopup dialog_title={cautionWhenDeletingStatusList.includes(activityPool.activityPoolStatus) ? `This AP is in ${activityPool.activityPoolStatus} state. Deleting it now will destroy all related records. Are you really sure you want to continue?` : `This can destroy important data, Are you sure you want to delete this ${activityPool.activityPoolStatus} AP?`} show_popup={showDeletePopup} set_show_popup={setShowDeletePopup} set_response={handleDeletePopupResponse} />
                                        <ConfirmActionPopup dialog_title={cautionWhenDeletingStatusList.includes(activityPool.activityPoolStatus) ? `This AP is in ${activityPool.activityPoolStatus} state. Cancelling it now will delete all registrations and return all monies to the appropriate places. Are you really sure you want to continue?` : `Are you sure you want to cancel this ${activityPool.activityPoolStatus} AP?`} show_popup={showCancelPopup} set_show_popup={setShowCancelPopup} set_response={handleCancelPopupResponse} />

                                        <div class="mt-6 flex items-center justify-end gap-x-6">
                                            {
                                                activityPool.activityPoolStatus === "OPEN"
                                                    ?
                                                    <button type="button" onClick={(e) => cancelActivityPool(e)} class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Cancel</button>
                                                    :
                                                    null
                                            }
                                            {
                                                activityPool.activityPoolStatus === "DRAFT" || activityPool.activityPoolStatus === "REVIEW" || activityPool.activityPoolStatus === "PUBLISHED"
                                                    ?
                                                    <button type="button" onClick={(e) => deleteActivityPool(e)} class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Delete</button>
                                                    :
                                                    null
                                            }
                                            {
                                                activityPool.activityPoolStatus === "DRAFT" || activityPool.activityPoolStatus === "REVIEW" || activityPool.activityPoolStatus === "PUBLISHED"
                                                    ?
                                                    <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                                    :
                                                    <span>Update Not Available</span>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div class="mt-6 mb-6 flex items-center justify-end gap-x-6">
                                        <div>Activity Pool Read Only Due to Status</div>
                                    </div>
                            }
                        </form>
                        :
                        tabIndex === 1
                            ?
                            <section id="registrations">
                                <ActivityPoolRegistrations activityPool={activityPool} set_subject_user_id={setSubjectUserId} />
                            </section>
                            :
                            tabIndex === 2
                                ?
                                <ActivityPoolRewardList activityPool={activityPool} />
                                :
                                tabIndex === 3
                                    ?
                                    <AccountListActivityPool activity_pool={activityPool} />
                                    :
                                    tabIndex === 4
                                        ?
                                        <ActivityPoolEntryCodeForm activity_pool_id={activityPool.activityPoolId} current_parent_refresh={refreshActivityPool} force_parent_refresh={setRefreshActivityPool} />
                                        :
                                        <ActivityPoolRewardCards activity_pool={activityPool} />
                    }
                </section>

            </div>
    );
};

export default ActivityPoolForm;