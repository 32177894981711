function formatCurrency(value, decimalPlaces = 2, currencySymbol = "$", symbolPosition = "BEFORE") {
    // Check if the value is a whole number
    const isWholeNumber = value % 1 === 0;
  
    // Format the value based on whether it's a whole number
    const formattedValue = isWholeNumber
      ? value.toString() // Use the integer representation if it's a whole number
      : value.toFixed(decimalPlaces); // Otherwise, format to the specified decimal places
  
    // Format the result based on symbol position
    if (symbolPosition === "BEFORE") {
      return `${currencySymbol}${formattedValue}`;
    } else if (symbolPosition === "AFTER") {
      return `${formattedValue} ${currencySymbol}`;
    }
  
    // Default fallback (symbol at the start)
    return `${currencySymbol}${formattedValue}`;
  }

  export default formatCurrency;
  