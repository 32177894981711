import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DisplayProgressMessage from './DisplayProgressMessage';
import AddSponsorPrizeButton from './AddSponsorPrizeButton';

const ActivityPoolRewardList = (props) => {
    const navigate = useNavigate();
    const [rewards, setRewards] = useState([]);
    const activityPool = props.activityPool;

    const [progressMessage, setProgressMessage] = useState([]);

    const fetchrewards = async () => {
        if (activityPool.rewards) {
            setRewards(activityPool.rewardData);
        }
        else {
            setRewards([]);
        }
    };

    const addDiscountCode = async (e) => {
        e.preventDefault();
        setProgressMessage("Add Discount Code Not Implemented");
    }

    const addBonusPrize = async (e) => {
        e.preventDefault();
        setProgressMessage("Please Wait ...");
        navigate(`/activitypoolreward/new/${activityPool.activityPoolId}`);
    }

    useEffect(() => {
        fetchrewards();
    }, []);

    const get_currency_description = (value, currency_code) => {
        if (currency_code === "EUR") return `€${value}`;
        else if (currency_code === "USD") return `$${value}`;
        else if (currency_code === "GBP") return `£${value}`;
        else return `${value} ${currency_code}`;
    }

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center mb-4">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{rewards.length} Activity Pool Rewards</h1>
                </div>
                {
                    activityPool.sponsorId && (activityPool.activityPoolStatus === "DRAFT" || activityPool.activityPoolStatus === "REVIEW")
                        ?
                        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <AddSponsorPrizeButton activity_pool={activityPool} amount={200} setProgressMessages={setProgressMessage} />
                        </div>
                        :
                        <></>
                }
                {
                    (activityPool.activityPoolStatus === "DRAFT" || activityPool.activityPoolStatus === "REVIEW")
                        ?
                        <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
                            <Link onClick={(e) => addDiscountCode(e)} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="">Add Discount Code</Link>
                        </div>
                        :
                        <></>
                }
            </div>
            <section class="mx-4" id="progressMessages">
                <DisplayProgressMessage progressMessage={progressMessage} />
            </section>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Reward Type</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Reward Amount</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {rewards
                                    .map(reward => (
                                        <tr key={reward.rewardCode} class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{reward.rewardType}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{reward.description}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{get_currency_description(reward.amount, reward.currencyCode)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityPoolRewardList;