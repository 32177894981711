import React, { useEffect, useState } from 'react';
import { NillionApi } from '../ApiConfig';
import { useNavigate } from 'react-router-dom';
import DisplayErrorMessages from './DisplayErrorMessages';

const UserProfileNillionForm = (props) => {
    const userId = props.userId;
    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState([]);
    const addErrorMessage = (newError) => { setErrorMessages([...errorMessages, newError]) };

    const [refresh, setRefresh] = useState(0);
    const [userProfile, setUserProfile] = useState({
        prefered_name: '',
        last_name: '',
        nickname: '',
        avatar: '',
        date_of_birth: '',
        biological_sex: '',
        gender: '',
        country_code: '',
        zip_code: '',
        town: '',
        ethnicity: '',
        mode: '',
        user_id: '',
        profile_image_url: '',
        paypal_email: '',
        height: {
            cm: '',
            ft: '',
            in: '',
            system: ''
        },
        weight: {
            st: '',
            lb: '',
            kg: '',
            system: ''
        }
    });

    useEffect(() => {
        if (userId) {
            const fetchUserProfile = async () => {
                try {
                    setErrorMessages([]);
                    const response = await NillionApi.get(`/user-profile/${userId}`);
                    setUserProfile(response.data);
                }
                catch (err) {
                    if (err.status === 404 && err.response && err.response.data) {
                        //Not Found
                        addErrorMessage(`GET-NILLION_USER-PROFILE: ${err.response.data} - You can force Nillion to update by navigating to the user profile page and clicking Save`);
                    }
                    else {
                        addErrorMessage(`Unexpected Error ${err.message}`);
                    }
                }
            };
            fetchUserProfile();
        }
    }, [userId, refresh]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserProfile({ ...userProfile, [name]: value });
    };

    return (
        userProfile.userId === ''
            ?
            <section>
                <span>Loading User Profile</span>
                {
                    errorMessages.length > 0
                        ?
                        <section class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                            <div class="flex-3">
                                <DisplayErrorMessages errorMessages={errorMessages} />
                            </div>
                        </section>

                        :
                        null
                }
            </section>
            :
            <div>
                <div class="mt-4">
                    <h1>NILLION USER PROFILE</h1>
                    {
                        errorMessages.length > 0
                            ?
                            <section class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <div class="flex-3">
                                    <DisplayErrorMessages errorMessages={errorMessages} />
                                </div>
                            </section>

                            :
                            null
                    }
                    <div class="space-y-12">
                        <div class="border-b border-gray-900/10 pb-12">
                            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div class="sm:col-span-6 bg-black text-white px-2">
                                    <h2 class="text-base font-semibold leading-7">User Profile (PrivyId: {userProfile.user_id})</h2>
                                </div>




                                <div class="sm:col-span-3">
                                    <label for="last_name" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                                    <div class="mt-2">
                                        <span>{userProfile.last_name ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="prefered_name" class="block text-sm font-medium leading-6 text-gray-900">Preferred Name</label>
                                    <div class="mt-2">
                                        <span>{userProfile.prefered_name ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="nickname" class="block text-sm font-medium leading-6 text-gray-900">Nickname</label>
                                    <div class="mt-2">
                                        <span>{userProfile.nickname ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="biological_sex" class="block text-sm font-medium leading-6 text-gray-900">Biological Sex</label>
                                    <div class="mt-2">
                                        <span>{userProfile.biological_sex ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender</label>
                                    <div class="mt-2">
                                        <span>{userProfile.gender ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="date_of_birth" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth</label>
                                    <div class="mt-2">
                                        <span>{userProfile.date_of_birth ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="town" class="block text-sm font-medium leading-6 text-gray-900">Town / City</label>
                                    <div class="mt-2">
                                        <span>{userProfile.town ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="zip_code" class="block text-sm font-medium leading-6 text-gray-900">Zip / Post Code</label>
                                    <div class="mt-2">
                                        <span>{userProfile.zip_code ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="country_code" class="block text-sm font-medium leading-6 text-gray-900">Country Code</label>
                                    <div class="mt-2">
                                        <span>{userProfile.country_code ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="ethnicity" class="block text-sm font-medium leading-6 text-gray-900">Ethnicity</label>
                                    <div class="mt-2">
                                        <span>{userProfile.ethnicity ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="paypal_email" class="block text-sm font-medium leading-6 text-gray-900">Paypal Email (Payout)</label>
                                    <div class="mt-2">
                                        <span>{userProfile.paypal_email ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="avatar" class="block text-sm font-medium leading-6 text-gray-900">Avatar</label>
                                    <div class="mt-2">
                                        <span>{userProfile.avatar ?? "NOT SET"}</span>
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="profile_image_url" class="block text-sm font-medium leading-6 text-gray-900">Profile Image Url</label>
                                    <div class="mt-2">
                                        <span>{userProfile.profile_image_url ?? "NOT SET"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-8">
                    <div class="sm:col-span-6 bg-black text-white px-2">
                        <h2 class="text-base font-semibold leading-7">Height and Weight</h2>
                    </div>
                    <div class="px-4 py-5 sm:p-6">
                        {
                            userProfile.height
                                ?
                                <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">Height: {userProfile.height.system === "IMPERIAL" ? userProfile.height.ft + 'ft' + userProfile.height.in + 'in' : userProfile.height.cm + 'cm'}  ( {userProfile.height.system} )</h3>
                                :
                                <></>
                        }
                        {
                            userProfile.weight
                                ?
                                <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">Weight: {userProfile.weight.system === "IMPERIAL" ? userProfile.weight.st + 'st' + userProfile.weight.lb + 'lbs' : userProfile.weight.kg + 'kg'}  ( {userProfile.height.system} )</h3>
                                :
                                <></>
                        }
                    </div>
                </div>

            </div>

    );
};

export default UserProfileNillionForm;