import React, { useEffect, useState } from 'react';
import { PaymentApi } from '../ApiConfig';
import moment from 'moment';

const ActivityPoolAccountList = (props) => {
    const [accountList, setAccountList] = useState([]);
    const activityPool = props.activity_pool;

    useEffect(() => {
        const fetchActivityPoolAccount = async (currency_code) => {
            try {
                const response = await PaymentApi.get(`/account-records/activity-pools/?activity_pool_id=${activityPool.activityPoolId}`);
                if (Array.isArray(response.data.data)) {
                    setAccountList(response.data.data);
                }
                else {
                    setAccountList([]);
                }
            }
            catch (err) {
                console.log(`Unexpected error list account record - ${err}`);
                setAccountList([]);
            }
        };
        if (activityPool) {
            fetchActivityPoolAccount(activityPool.currencyCode)
        }
    }, [activityPool]);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <h1>Currency: {activityPool.currencyCode}</h1>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">User</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">DR</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">CR</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {accountList
                                    .map(accountItem => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{moment(accountItem.createdAt).format('DD/MM/YYYY')}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.activityTitle ? accountItem.activityTitle : accountItem.type}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{accountItem.userId}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.debit ? accountItem.debit : accountItem.amount < 0 ? (accountItem.amount * -1).toFixed(2) : ''}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.credit ? accountItem.credit : accountItem.amount > 0 ? accountItem.amount.toFixed(2) : ''}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityPoolAccountList;