import { useState, useEffect } from "react";

export default function ActivityPoolRewardCards(props) {
    const [activityPool, setActivityPool] = useState(props.activity_pool);
    
    useEffect(() => {
        setActivityPool(props.activity_pool);
    }, [props.activity_pool]);    

    return (
        <div>
            <div className="px-4 sm:px-6 lg:px-8 mt-4">
                <div className="sm:flex sm:items-center mb-4">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Activity Pool Cards</h1>
                    </div>
                </div>
                <div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {
                                    activityPool.rewards.map((reward, index) => (
                                        reward.reward_type === "PRIZE_POOL"
                                            ? 
                                            <div key={index} className="relative overflow-hidden rounded-lg shadow w-96 mb-8 bg-hb_prize_card_participant text-hb_prize_card_text text-xs h-48 flex flex-col justify-between p-2">
                                                <div className="px-2 mb-2 text-lg">
                                                    <p>{reward.primary_title}</p>
                                                </div>
                                                <div className="absolute top-8 left-4 text-4xl font-bold">
                                                    {reward.primary_display_value}
                                                </div>
                                                <div className="absolute top-2 right-4 text-lg">
                                                    <p>{reward.secondary_title}</p>
                                                </div>                                                
                                                <div className="absolute top-8 right-2 text-2xl font-bold">
                                                    {reward.secondary_display_value}
                                                </div>
                                                <div className="mt-auto p-2">
                                                    <p>{reward.message}</p>
                                                </div>
                                            </div>
                                            :
                                            reward.bonus_prize_reward_type === "EQUAL"
                                            ? 
                                            <div key={index} className="relative overflow-hidden rounded-lg shadow w-96 mb-8 bg-hb_prize_card_equal text-hb_prize_card_sponsor_text text-xs h-48 flex flex-col justify-between p-2">
                                                <div className="px-2 mb-2 text-lg">
                                                    <p>{reward.primary_title}</p>
                                                </div>
                                                <div className="absolute top-8 left-4 text-4xl font-bold">
                                                    {reward.primary_display_value}
                                                </div>
                                                <div className="absolute top-2 right-4 text-lg">
                                                    <p>{reward.secondary_title}</p>
                                                </div>                                                
                                                <div className="absolute top-8 right-2 text-2xl font-bold">
                                                    {reward.secondary_display_value}
                                                </div>
                                                <div className="mt-auto p-2">
                                                    <p>{reward.message}</p>
                                                </div>
                                            </div>
                                            :
                                            reward.bonus_prize_reward_type === "FIXED"
                                            ?
                                            <div key={index} className="relative overflow-hidden rounded-lg shadow w-96 mb-8 bg-hb_prize_card_fixed text-hb_prize_card_sponsor_text text-xs h-48 flex flex-col justify-between p-2">
                                                <div className="px-2 mb-2 text-lg">
                                                    <p>{reward.primary_title}</p>
                                                </div>
                                                <div className="absolute top-8 left-4 text-4xl font-bold">
                                                    {reward.primary_display_value}
                                                </div>
                                                <div className="absolute top-16 left-2 text-2xl font-bold">
                                                    <p>{reward.secondary_title}</p>
                                                    {reward.secondary_display_value}
                                                </div>
                                                <div className="mt-auto p-2">
                                                    <p>{reward.message}</p>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} className="relative overflow-hidden rounded-lg shadow w-96 mb-8 bg-hb_prize_card_lottery text-hb_prize_card_sponsor_text text-xs h-48 flex flex-col justify-between p-2">
                                                <div className="px-2 mb-2 text-lg">
                                                    <p>{reward.primary_title}</p>
                                                </div>
                                                <div className="absolute top-8 left-4 text-4xl font-bold">
                                                    {reward.primary_display_value}
                                                </div>
                                                <div className="absolute top-2 right-4 text-lg">
                                                    <p>{reward.secondary_title}</p>
                                                </div>                                                
                                                <div className="absolute top-8 right-2 text-2xl font-bold">
                                                    {reward.secondary_display_value}
                                                </div>
                                                <div className="mt-auto p-2">
                                                    <p>{reward.message}</p>
                                                </div>
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
