import axios from "axios";
import { getAccessToken } from '@privy-io/react-auth';

// Create a function to initialize API instances with interceptors
const createApiInstance = (baseURL) => {
  const apiInstance = axios.create({ baseURL });

  apiInstance.interceptors.response.use(
    (response) => {
      console.log(response.data);
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  apiInstance.interceptors.request.use(
    async (config) => {
      var  now = new Date();
      var formattedDateTime = now.toISOString().replace('T', ' ').replace('Z', '');
      console.log(`Get Privy Token: ${formattedDateTime}`);

      const token = await getAccessToken();

      now = new Date();
      formattedDateTime = now.toISOString().replace('T', ' ').replace('Z', '');
      console.log(`Got Privy Token: ${formattedDateTime}`);
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return apiInstance;
};

const UserApi = createApiInstance(process.env.USER_API_URL || "https://user-api.healthblocks.ai");
const UserProfileApi = createApiInstance(process.env.USER_PROFILE_API_URL || "https://user-profile-api.healthblocks.ai");
const CurrencyApi = createApiInstance(process.env.CURRENCY_API_URL || "https://currency-api.healthblocks.ai");
const ActivityPoolSponsorApi = createApiInstance(process.env.ACTIVITY_POOL_SPONSOR_API_URL || "https://activity-pool-sponsor-api.healthblocks.ai");
const ActivityPoolApi = createApiInstance(process.env.ACTIVITY_POOL_API_URL || "https://activity-pool-api.healthblocks.ai");
const PaymentApi = createApiInstance(process.env.PAYMENT_API_URL || "https://payment-api.healthblocks.ai");
const HealthDataApi = createApiInstance(process.env.REACT_APP_HEALTH_DATA_API_URL || "https://oak.healthblocks.ai");
const DataPlatformApi = createApiInstance(process.env.REACT_APP_DATA_PLATFORM_API_URL || "https://healthblocks-data-plat-git-8c5fcd-jeff-gleeds-projects-6926702d.vercel.app/api");
const NillionApi = createApiInstance(process.env.NILLION_API_URL || "https://nillion-api.healthblocks.ai");

export {
  UserApi,
  UserProfileApi,
  CurrencyApi,
  ActivityPoolSponsorApi,
  ActivityPoolApi,
  PaymentApi,
  HealthDataApi,
  DataPlatformApi,
  NillionApi
};
