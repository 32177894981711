import React, { useState, useEffect } from 'react';
import { ActivityPoolApi, PaymentApi } from '../ApiConfig';
import { getAccessToken } from '@privy-io/react-auth';

const EnterActivityPoolButton = (props) => {

    var activityPool = props.activityPool;
    var setRefreshRegistration = props.setRefreshRegistration;
    const [progressMsg, setProgressMsg] = useState();
    const [entryCode, setEntryCode] = useState();
    const [requireEntryCode, setRequireEntryCode] = useState(false);
    const [canPayWithWalletBalance, setCanPayWithWalletBalance] = useState(false);

    var refreshKey = 1;

    useEffect(() => {
        const run_pre_flight = async (activity_pool_id) => {
            //Call Activity Pool Pre Flight Check
            const preFlightResponse = await PaymentApi.get(`/transactions/pre-flight?activity_pool_id=${activity_pool_id}`)
            if (preFlightResponse && preFlightResponse.data) {

                const preFlight = preFlightResponse.data;

                if (preFlight.alreadyRegistered) {
                    setProgressMsg("You are already registred for this activity pool");
                    return;
                }

                if (preFlight.wouldExceedMaxParticipants) {
                    setProgressMsg("This activity pool is full. Registration is not possible");
                    return;
                }

                setRequireEntryCode(preFlight.requireEntryCode);
                setCanPayWithWalletBalance(preFlight.canPayWithWalletBalance);
            }
        }
        run_pre_flight(activityPool.activityPoolId)
    }, [activityPool]);

    const enterPool = async (pay_with_wallet_balance) => {
        try {
            //Need to call payment api to join pool. IF we are paying it creates a transaction with payment provider then redirect to returned location. 
            var paymentRequest = {
                "product": {
                    "type": "ACTIVITY_POOL_ENTRY",
                    "activityPoolId": activityPool.activityPoolId,
                },
                "entry_code": entryCode,
                "payWithWalletBalance": pay_with_wallet_balance,
                "returnUrl": `https://everest.healthblocks.ai/paymentcomplete`
            };
            setProgressMsg('Requesting activity pool entry ... please wait');
            var response = await PaymentApi.post('https://payment-api.healthblocks.ai/transactions/', paymentRequest);
            //var response = await PaymentApi.post('https://dev-payment-api.healthblocks.ai/transactions/', paymentRequest);
            //var response = await PaymentApi.post('http://localhost:8000/transactions/', paymentRequest);
            if(response.data.no_redirect_required) {
                //Registration already completed, no need to redirect to request payment
                setProgressMsg("Registration Completed");
                setRefreshRegistration(refreshKey++);
            }
            else {
                //Redirect to the payment provides page
                var makePaymentUrl = response.data.redirect_url;
                setProgressMsg(`Redirecting to payment (${makePaymentUrl})... please wait`);
                window.location.href = makePaymentUrl;
            }
        }
        catch (err) {
            setProgressMsg(err.response && err.response.data ? JSON.stringify(err.response.data) : err.message);
        }
    }

    return (
        activityPool.activityPoolStatus === "OPEN" || activityPool.activityPoolStatus === "REVIEW"
            ?
            <section>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    <label className='mx-4'>Enter Pool {requireEntryCode ? " (AP Requires Entry Code)" : null}</label>
                    {
                        requireEntryCode
                            ?
                            <>
                                <label className='ml-4 mr-2'>Entry Code:</label>
                                <input type="text" name="entryCode" value={entryCode} onChange={(e) => setEntryCode(e.target.value)} required id="entryCode" className="mr-4  p-1 w-30 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                            </>
                            :
                            null
                    }
                    {
                        canPayWithWalletBalance && activityPool.entryFee > 0
                        ?
                        <button type="button" id="enterPoolWithWalletBalance" onClick={() => enterPool(true)} class="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Pay with wallet balance</button>
                        :
                        null
                    }
                    {
                        activityPool.entryFee > 0
                        ?
                        <button type="button" id="enterPoolAndPay" onClick={() => enterPool(false)} class="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Pay to enter</button>
                        :
                        <button type="button" id="enterFreePool" onClick={() => enterPool(false)} class="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enter Free Pool</button>

                    }
                </div>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    {
                        progressMsg
                            ?
                            <div>
                                <span class="block text-xs font-medium leading-6 text-gray-400">{progressMsg}</span>
                            </div >
                            :
                            <span></span>
                    }
                </div>

            </section >
            :
            <section>Unable to Join this AP</section>


    );
};

export default EnterActivityPoolButton;