import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserList from './components/UserList';
import UserForm from './components/UserForm';
import HomePage from './components/HomePage';
import CurrencyList from './components/CurrencyList';
import CurrencyForm from './components/CurrencyForm';
import LoginForm from './components/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import { Link } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import UserProfileList from './components/UserProfileList';
import UserProfileForm from './components/UserProfileForm';
import APSponsorList from "./components/APSponsorList";
import APSponsorForm from './components/APSponsorForm';
import ActivityPoolList from './components/ActivityPoolList';
import ActivityPoolForm from './components/ActivityPoolForm';
import SponsorPrizeForm from './components/SponsorPrizeForm';
import PaymentComplete from './components/PaymentComplete';
import DataPoolList from './components/DataPoolList';
import DataPoolForm from './components/DataPoolForm';
import DeveloperTools from './components/DeveloperTools';
import AccountList from './components/AccountList';
import CommissionList from './components/CommissionList';

const App = () => {
    const { ready, authenticated, user, useLogout } = usePrivy();
    const loggedIn = (ready && authenticated);
    const [smallMenuState, setSmallMenuState] = useState();
    const [isAdminUser, setIsAdminUser] = useState(false);

    return (
        <Router>
            <nav class="bg-gray-800">
                <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="relative flex h-16 items-center justify-between">
                        {
                            isAdminUser
                                ?
                                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    <button type="button" onClick={() => setSmallMenuState(!smallMenuState)} class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                        <span class="absolute -inset-0.5"></span>
                                        <span class="sr-only">Open main menu</span>
                                        <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                        </svg>
                                        <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                :
                                ""
                        }

                        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                            <div class="flex shrink-0 items-center">
                                <Link to="/" class="rounded-md bg-gray-900 text-sm font-medium text-white" aria-current="page">
                                    <img class="h-8 w-auto" src="/healthblockslogo-sm.png" alt="Healthblocks Logo"></img>
                                </Link>
                            </div>
                            <div class="hidden sm:ml-6 sm:block">
                                {
                                    isAdminUser
                                        ?
                                        <div class="flex space-x-4">
                                            <Link to="/users" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Users</Link>
                                            <Link to="/currencies" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Currencies</Link>
                                            <Link to="/apsponsors" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Sponsors</Link>
                                            <Link to="/activitypools" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Activity Pools</Link>
                                            <Link to="/commission" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Commission</Link>
                                        </div>
                                        :
                                        ""
                                }

                            </div>
                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div class="relative ml-3">
                                <div>
                                    <LoginForm set_is_admin_user={setIsAdminUser} />
                                </div>
                                <div class="absolute hidden right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    smallMenuState && loggedIn
                        ?
                        <div class="sm:hidden" id="mobile-menu" >
                            <div class="space-y-1 px-2 pb-3 pt-2">
                                <Link onClick={() => setSmallMenuState(false)} to="/users" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Users</Link>
                                <Link onClick={() => setSmallMenuState(false)} to="/currencies" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Currencies</Link>
                                <Link onClick={() => setSmallMenuState(false)} to="/apsponsors" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Sponsors</Link>
                                <Link onClick={() => setSmallMenuState(false)} to="/activitypools" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Activity Pools</Link>
                                <Link onClick={() => setSmallMenuState(false)} to="/commission" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Commission</Link>
                            </div>
                        </div>
                        :
                        ""
                }

            </nav>

            <div id="content" class="container mx-auto px-4">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
                    <Route path="/userprofiles" element={<PrivateRoute><UserProfileList /></PrivateRoute>} />
                    <Route path="/userprofiles/new" element={<PrivateRoute><UserProfileForm /></PrivateRoute>} />
                    <Route path="/userprofiles/:userProfileId" element={<PrivateRoute><UserProfileForm /></PrivateRoute>} />
                    <Route path="/users/new" element={<PrivateRoute><UserForm /></PrivateRoute>} />
                    <Route path="/users/:userId" element={<UserForm />} />
                    <Route path="/currencies" element={<PrivateRoute><CurrencyList /></PrivateRoute>} />
                    <Route path="/currencies/new" element={<CurrencyForm />} />
                    <Route path="/currencies/:currencyId" element={<CurrencyForm />} />
                    <Route path="/apsponsors" element={<PrivateRoute><APSponsorList /></PrivateRoute>} />
                    <Route path="/apsponsors/new" element={<APSponsorForm />} />
                    <Route path="/apsponsors/:sponsorId" element={<APSponsorForm />} />
                    <Route path="/activitypools" element={<PrivateRoute><ActivityPoolList /></PrivateRoute>} />
                    <Route path="/activitypools/new" element={<PrivateRoute><ActivityPoolForm /></PrivateRoute>} />
                    <Route path="/activitypools/new/:sponsorId" element={<PrivateRoute><ActivityPoolForm /></PrivateRoute>} />
                    <Route path="/activitypool/:activityPoolId" element={<PrivateRoute><ActivityPoolForm /></PrivateRoute>} />
                    <Route path="/sponsorprizes/new/:activityPoolId" element={<PrivateRoute><SponsorPrizeForm /></PrivateRoute>} />
                    <Route path="/paymentcomplete" element={<PaymentComplete />} />
                    <Route path="/paymentcomplete/:transactionId" element={<PaymentComplete />} />
                    <Route path="/datapools" element={<PrivateRoute><DataPoolList /></PrivateRoute>} />
                    <Route path="/datapools/new" element={<PrivateRoute><DataPoolForm /></PrivateRoute>} />
                    <Route path="/datapools/new/:sponsorId" element={<PrivateRoute><DataPoolForm /></PrivateRoute>} />
                    <Route path="/datapools/:dataPoolId" element={<PrivateRoute><DataPoolForm /></PrivateRoute>} />
                    <Route path="/devtools" element={<PrivateRoute><DeveloperTools /></PrivateRoute>} />
                    <Route path="/commission" element={<PrivateRoute><CommissionList healthblocks="true" /></PrivateRoute>} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
