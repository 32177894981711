import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { PaymentApi, ActivityPoolApi } from '../ApiConfig';

function PaymentComplete() {
    const [searchParams] = useSearchParams();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [activityPoolId, setActivityPoolId] = useState('');
    const [activityPoolTitle, setActivityPoolTitle] = useState('');

    useEffect(() => {
        const fetchPaymentTransaction = async (transaction_id) => {
            try {
                const response = await PaymentApi.get(`/transactions/${transaction_id}`);
                if (response && response.data) {
                    if (response.data.status === "PAID") {
                        setActivityPoolId(response.data.activityPoolId);
                        setPaymentSuccess(true);
                    }
                }
            } catch (err) {
                console.log(`Unexpected error checking transaction status - ${err}`);
            }
        };
        const transaction_id = searchParams.get('transaction_id');
        if (transaction_id) {
            fetchPaymentTransaction(transaction_id);
        }
    }, [searchParams]);

    useEffect(() => {
        const fetchActivityPool = async (activity_pool_id) => {
            try {
                const response = await ActivityPoolApi.get(`/activity-pools/${activity_pool_id}`);
                if (response && response.data) {
                    setActivityPoolTitle(response.data.title);
                }
            } catch (err) {
                console.log(`Unexpected error checking transaction status - ${err}`);
            }
        };
        if (activityPoolId) {
            fetchActivityPool(activityPoolId);
        }
    }, [activityPoolId]);

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            {paymentSuccess ? (
                <>
                    <h1 className="text-3xl font-bold text-center text-green-600">
                        Thank you, your payment was successful for entry into Activity Pool
                    </h1>
                    <Link
                        to={`/activitypool/${activityPoolId}`}
                        className="mt-8 text-2xl font-bold text-center text-blue-600 hover:text-blue-800"
                    >
                        {activityPoolTitle}
                    </Link>
                </>
            ) : (
                <>
                    <h1 className="text-3xl font-bold text-center text-red-600">
                        Payment was not successful for entry into
                    </h1>
                    <Link
                        to={`/activitypool/${activityPoolId}`}
                        className="mt-8 text-2xl font-bold text-center text-blue-600 hover:text-blue-800"
                    >
                        {activityPoolTitle}
                    </Link>
                </>
            )}
        </div>
    );
}

export default PaymentComplete;
