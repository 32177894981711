import React, { useEffect, useState } from 'react';
import { ActivityPoolApi } from '../ApiConfig';
import DisplayErrorMessages from './DisplayErrorMessages';
import { ExportToExcel } from './ExportToExcel';

export default function ActivityPoolEntryCodeList(props) {
    const [entryCodes, setEntryCodes] = useState([]);
    const [errorMessages, setErrorMessages] = useState([])

    const addErrorMessage = (newError) => { setErrorMessages([...errorMessages, newError]) };

    useEffect(() => {
        const fetchEntryCodes = async (activity_pool_id) => {
            try {
                setErrorMessages([])
                const response = await ActivityPoolApi.get(`/entry-codes/?activity_pool_id=${activity_pool_id}&allocated_state=unallocated`);
                const filteredData = response.data.map(item => {
                    return { entryCode: item.entryCode };
                });
                setEntryCodes(filteredData);
            }
            catch (err) {
                addErrorMessage(err.message);
            }
        };
        fetchEntryCodes(props.activity_pool_id);
    }, [props.activity_pool_id, props.force_refresh]);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="my-2">
                <DisplayErrorMessages errorMessages={errorMessages} />
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="my-2">

                        </div>
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Available Entry Codes <ExportToExcel apiData={entryCodes} fileName="activity-pool-entry-codes" /></th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {entryCodes
                                    .map(entryCode => (
                                        <tr key={entryCode.entryCode} class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{entryCode.entryCode}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
