import React, { useEffect, useState } from 'react';
import { PaymentApi } from '../ApiConfig';
import moment from 'moment';
import PayoutButton from './PayoutButton';
import { Link } from 'react-router-dom';



const AccountList = (props) => {
    const [accountList, setAccountList] = useState([]);
    const [balances, setBalances] = useState([]);
    const [currencyCode, setCurrencyCode] = useState([]);
    const [accountUserId, setAccountUserId] = useState([]);
    const [refresh, setRefresh] = useState([]);

    const user_id = props.user_id;
    const activityPoolSponsor = props.activity_pool_sponsor;
    const activityPool = props.activity_pool;
    const healthblocks = props.healthblocks;

    useEffect(() => {
        const fetchHealthblocksAccount = async () => {
            console.log("Fetching Healthblocks Account List")

            try {
                const response = await PaymentApi.get(`/account-records/hb-commission`);
                if (Array.isArray(response.data.data)) {
                    setAccountList(response.data.data);
                }
                else {
                    setAccountList([]);
                }
            }
            catch (err) {
                console.log(`Unexpected error list account record - ${err}`);
                setAccountList([]);
            }
        };

        if (healthblocks) {
            fetchHealthblocksAccount()
        }
    }, [healthblocks]);

    useEffect(() => {
        const fetchBalances = async () => {
            console.log(`Fetching Balances ${user_id}`)
            try {
                const response = await PaymentApi.get(
                    `/wallet/summary/?user_id=${user_id}`
                );
                const balances = response.data.data;
                setBalances(balances);
                if (balances.length > 0) {
                    setAccountUserId(user_id);
                    setCurrencyCode(balances[0].currencyCode);
                }
            } catch (err) {
                console.log(`Error fetching balance: ${err}`);
            }
        };

        if (user_id) {
            fetchBalances()
        }
    }, [user_id]);

    useEffect(() => {
        const fetchUserAccount = async (account_user_id, account_currency_code) => {
            console.log(`Fetching User Account List userid:${account_user_id} currency:${account_currency_code}`)
            try {
                const response = await PaymentApi.get(`/account-records/?user_id=${account_user_id}&currency_code=${account_currency_code}`);
                if (Array.isArray(response.data.data)) {
                    setAccountList(response.data.data);
                }
                else {
                    setAccountList([]);
                }
            }
            catch (err) {
                console.log(`Unexpected error list account record - ${err}`);
                setAccountList([]);
            }
        };
        if (accountUserId && accountUserId.length > 0 && currencyCode && currencyCode.length > 0) {
            fetchUserAccount(accountUserId, currencyCode);
        }
    }, [accountUserId, currencyCode, refresh]);


    //Handle AP Sponsor Account Lists
    useEffect(() => {
        const fetchApSponsorAccount = async (sponsor_id) => {
            console.log("Fetching Sponsor List")

            try {
                const response = await PaymentApi.get(`/account-records/sponsored-ap/?sponsor_id=${sponsor_id}`);
                if (Array.isArray(response.data.data)) {
                    setAccountList(response.data.data);
                }
                else {
                    setAccountList([]);
                }
            }
            catch (err) {
                console.log(`Unexpected error getting AP Sponsor account records - ${err}`);
                setAccountList([]);
            }
        };
        if (activityPoolSponsor && activityPoolSponsor.length > 0) {
            fetchApSponsorAccount(activityPoolSponsor.sponsor_id)
        }
    }, [activityPoolSponsor]);

    //Handle update of Balance Currency Dropdown
    const handleChange = (e) => {
        const { value } = e.target;
        if (value) {
            setCurrencyCode(value);
        }
    };

    const get_account_record_title = (record_type, ap_title) => {
        if (record_type === "ACTIVITY_POOL_ENTRY" && ap_title) {
            return `Entry to: ${ap_title}`;
        }
        else if (record_type === "REFUNDED_ENTRY_FEE" && ap_title) {
            return `Refunded Entry Fee: ${ap_title}`;
        }
        else if (record_type === "ACTIVITY_POOL_PRIZE" && ap_title) {
            return `Prize: ${ap_title}`;
        }
        else if (record_type === "REFUNDED_ENTRY_FEE" && ap_title) {
            return `Refunded Entry Fee ${ap_title}`;
        }
        else if (record_type === "ACTIVITY_POOL_PAYOUT" && ap_title) {
            return `Withrawal ${ap_title}`;
        }
        return record_type;
    }

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            {
                user_id
                    ?
                    <div class="flex flex-col sm:flex-row sm:space-x-4">
                        <div class="flex flex-col mb-4 sm:mb-0">
                            <label for="currency_filter" class="block text-sm font-medium leading-6 text-gray-900">Account Balances</label>
                            <select
                                id="currency_filter"
                                name="currency_filter"
                                onChange={(e) => handleChange(e)}
                                placeholder="Currency Code"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                {balances.map(balance => (
                                    <option value={balance.currencyCode}>{`${balance.currencyCode} ${balance.balance}`}</option>
                                ))}
                            </select>
                        </div>
                        <PayoutButton currency_code={currencyCode} account_user_id={accountUserId} refresh={setRefresh} />
                    </div>
                    :
                    activityPool
                        ?
                        <h1>Currency: {activityPool.currencyCode}</h1>
                        :
                        activityPoolSponsor
                            ?
                            <h1>activityPoolSponsor.id</h1>
                            :
                            <h1>Healthblocks</h1>
            }


            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">DR</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">CR</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {accountList
                                    .map(accountItem => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{moment(accountItem.createdAt).format('DD/MM/YYYY')}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.activityPoolId ? <Link class="text-indigo-600 hover:text-indigo-900" to={`/activitypool/${accountItem.activityPoolId}#account`}>{accountItem.clientDescription}</Link> : accountItem.clientDescription}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.debit ? accountItem.debit : accountItem.amount < 0 ? (accountItem.amount * -1).toFixed(2) : ''}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 text-right">{accountItem.credit ? accountItem.credit : accountItem.amount > 0 ? accountItem.amount.toFixed(2) : ''}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountList;