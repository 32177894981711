import { Description, Field, Label, Switch } from '@headlessui/react'
import { useState, useEffect } from "react"

export default function CheckBox(props) {
    const label = props.label;
    const description = props.description;
    const onChange = props.onChange;
    const [enabled, setEnabled] = useState(props.initial_value)
    
    useEffect(() => {
        setEnabled(props.initial_value); // Update state if initial value changes
    }, [props.initial_value]);    

    const handleToggle = (newValue) => {
        setEnabled(newValue);
        onChange(newValue);
    };

    return (
        <Field className="flex items-center justify-between">
            <span className="flex grow flex-col">
                <Label as="span" passive className="text-sm/6 font-medium text-gray-900">
                    {label ? label : "Please enter a label!"}
                </Label>
                <Description as="span" className="text-sm text-gray-500">
                    {description ? description : "Please enter a description!"}
                </Description>
            </span>
            <Switch
                checked={enabled}
                onChange={handleToggle}
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
            >
                <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
            </Switch>
        </Field>
    )
}
