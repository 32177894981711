import { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import ActivityPoolEntryCodeList from "./ActivityPoolEntryCodeList";
import { ActivityPoolApi } from '../ApiConfig';

export default function ActivityPoolEntryCodeForm(props) {
    const [showChangeNumCodes, setShowChangeNumCodes] = useState(false);
    const [entryCodesInUse, setEntryCodesInUse] = useState([]);
    const [totalEntryCodes, setTotalEntryCodes] = useState([]);
    const [availableEntryCodes, setAvailableEntryCodes] = useState([]);
    const [newTotalEntryCodes, setNewTotalEntryCodes] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(0);
    const activity_pool_id = props.activity_pool_id;

    useEffect(() => {
        const fetchEntryCodes = async (activityPoolId) => {
            console.log('Fetch Activity Pool Entry Codes For:' + activityPoolId);
            const response = await ActivityPoolApi.get(`/entry-codes/summary?activity_pool_id=${activityPoolId}`);
            if(response && response.data) {
                setTotalEntryCodes(response.data.totalEntryCodes);
                setNewTotalEntryCodes(totalEntryCodes);
                setEntryCodesInUse(response.data.totalAllocated);
                setAvailableEntryCodes(response.data.totalNotAllocated);
            }
        }
        fetchEntryCodes(props.activity_pool_id);
    }, [props.activity_pool_id, totalEntryCodes, forceRefresh]);

    const updateEntryCodes = async (e, activity_pool_id) => {
        e.preventDefault();
        await ActivityPoolApi.post(`/entry-codes/?activity_pool_id=${activity_pool_id}&set_num_entry_codes_to=${newTotalEntryCodes}`);
        setForceRefresh(forceRefresh+1);
        props.force_parent_refresh(props.current_parent_refresh+1);
    }

    return (
        <section>
            <div className="sm:col-span-2 mt-4">
                <label htmlFor="currentEntryCodes" className="block text-sm font-medium leading-6 text-gray-900">Entry Codes</label>
                <div className="mt-4 text-sm text-gray-600">
                    <label>{`${availableEntryCodes} of ${totalEntryCodes} available. ${entryCodesInUse} already used`}</label>
                    <Link onClick={(event) => {
                        event.preventDefault();
                        setShowChangeNumCodes(!showChangeNumCodes);
                    }} to="#changeEntryCodes" className="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{showChangeNumCodes ? "Hide" : "Change"}</Link>
                </div>
                {
                    showChangeNumCodes
                        ?
                        <div className="mt-8 text-sm text-gray-600">
                            <label>New Total Codes</label>
                            <input type="number" name="newNumEntryCodes" value={newTotalEntryCodes} min={entryCodesInUse} onChange={(e) => setNewTotalEntryCodes(parseInt(e.target.value))} required id="newNumEntryCodes" className="ml-4 mr-4  p-1 w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                            {
                                newTotalEntryCodes < entryCodesInUse
                                    ?
                                    `Must be >= ${entryCodesInUse}`
                                    :
                                    newTotalEntryCodes === totalEntryCodes
                                        ?
                                        null
                                        :
                                        <Link onClick={(e) => {
                                            e.preventDefault(); 
                                            updateEntryCodes(e, activity_pool_id)
                                        }} to="#updateEntryCodes" className="ml-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Update</Link>
                            }
                        </div>
                        :
                        null
                }

            </div>
            <ActivityPoolEntryCodeList activity_pool_id={props.activity_pool_id} force_refresh={forceRefresh} />
        </section>
    )
}
