import React, { useEffect, useState } from 'react';
import { UserApi } from '../ApiConfig';
import { Link } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import CopyToClipboard from './CopyToClipboard';


const UserList = () => {
    const [users, setUsers] = useState([]);
    const [emailFilter, setEmailFilter] = useState();
    const [phoneFilter, setPhoneFilter] = useState();
    const [userIdFilter, setUserIdFilter] = useState();
    const [userNameFilter, setUserNameFilter] = useState();
    const { user } = usePrivy();

    const fetchUsers = async () => {
        const criteria = [];
        if (emailFilter) criteria.push(`email=${encodeURIComponent(emailFilter)}`);
        if (phoneFilter) criteria.push(`phone=${encodeURIComponent(phoneFilter)}`);
        if (userIdFilter) criteria.push(`user_id=${userIdFilter}`);
        if (userNameFilter) criteria.push(`user_name=${userNameFilter}`);
        const queryString = criteria.length > 0 ? '?' + criteria.join('&') : ''
        const response = await UserApi.get(`/users/filtered/${queryString}`);
        setUsers(response.data.data);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [emailFilter, phoneFilter, userIdFilter, userNameFilter]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "email_filter") {
            setEmailFilter(value);
        }
        else if (name === "phone_filter") {
            setPhoneFilter(value);
        }
        else if (name === "user_id_filter") {
            setUserIdFilter(value);
        }
        else if (name === "user_name_filter") {
            setUserNameFilter(value);
        }
    };

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="flex flex-wrap xl:flex-nowrap xl:space-x-4">
                <div class="flex flex-col w-full xl:w-auto">
                    <label for="user_id_filter" class="block text-sm font-medium leading-6 text-gray-900">User Id</label>
                    <input name="user_id_filter" value={userIdFilter} onChange={handleChange} placeholder="User Id" id="user_id_filter" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
                <div class="flex flex-col w-full xl:w-auto">
                    <label for="user_name_filter" class="block text-sm font-medium leading-6 text-gray-900">User Name</label>
                    <input name="user_name_filter" value={userNameFilter} onChange={handleChange} placeholder="User Name" id="user_name_filter" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
                <div class="flex flex-col w-full xl:w-auto mb-4 sm:mb-0">
                    <label for="email_filter" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <input name="email_filter" value={emailFilter} onChange={handleChange} placeholder="Email" id="email_filter" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
                <div class="flex flex-col w-full xl:w-auto">
                    <label for="phone_filter" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                    <input name="phone_filter" value={phoneFilter} onChange={handleChange} placeholder="Phone" id="phone_filter" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                </div>
                {
                    user
                        ?
                        <div class="flex flex-col w-full xl:w-auto">
                            <label class="block text-sm font-medium leading-6 text-gray-900">My Details</label>
                            <div class="flex space-x-4">
                                <Link class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to={`/users/${user.id}`}>View</Link>
                                <section className='mt-2'>
                                    <CopyToClipboard value={user.id} />
                                </section>
                            </div>
                        </div>

                        :
                        null
                }
            </div>


            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle">
                        <table class="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">User Name</th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">Email</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell">Phone</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">Role</th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter  hidden lg:table-cell">Date Added</th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter md:table-cell">User Id</th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">Copy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(current_user => (
                                    <tr key={current_user.id}>
                                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                                            <Link class="text-indigo-600 hover:text-indigo-900" to={`/users/${current_user.userId}`}>Edit</Link>
                                        </td>
                                        <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">{current_user.userName}</td>
                                        <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">{current_user.email}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 md:table-cell">{current_user.phone}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 sm:table-cell">{current_user.role}</td>
                                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500  hidden lg:table-cell">{current_user.signUpDate}</td>
                                        <td class="hidden whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 md:table-cell">{current_user.userId}</td>
                                        <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                            <CopyToClipboard value={current_user.userId} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;