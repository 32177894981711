import React, { useEffect, useState } from 'react';
import { ActivityPoolApi } from '../ApiConfig';
import axios from 'axios';

const CommissionList = () => {
    const [commissions, setCommissions] = useState([]);
    const [activityPoolIdFilter, setActivityPoolIdFilter] = useState('');
    const [activityPools, setActivityPools] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    // Function to fetch commissions from the API
    const fetchCommissions = async () => {
        try {
            const criteria = [];
            if (activityPoolIdFilter) criteria.push(`activity_pool_id=${activityPoolIdFilter}`);
            const queryString = criteria.length > 0 ? '?' + criteria.join('&') : '';

            const response = await axios.get(`https://payment-api.healthblocks.ai/account-records/hb-commission/${queryString}`);
            let data = response.data.data;

            // Sort the data by date in descending order (newest first)
            data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setCommissions(data);
            setTotalAmount(data.reduce((sum, commission) => sum + commission.amount, 0)); // Calculate total amount
        } catch (error) {
            console.error("Error fetching commissions:", error);
        }
    };

    useEffect(() => {
        fetchCommissions();
    }, []);

    useEffect(() => {
        fetchCommissions();
    }, [activityPoolIdFilter]);

    // Function to format numbers with two decimal places only when needed
    const formatAmount = (amount) => {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };    

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-4">
            <div className="flex flex-col sm:flex-row sm:space-x-4">
                {/* Filter input */}
                <div className="flex flex-col mb-4 sm:mb-0">
                    <label htmlFor="activity_pool_id_filter" className="block text-sm font-medium leading-6 text-gray-900">
                        Activity Pool ID
                    </label>
                    <input
                        name="activity_pool_id_filter"
                        value={activityPoolIdFilter}
                        onChange={(e) => setActivityPoolIdFilter(e.target.value)}
                        placeholder="Filter by Activity Pool ID"
                        id="activity_pool_id_filter"
                        className="p-1 block w-full sm:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                {/* Total amount */}
                <div className="flex items-center text-lg font-semibold text-gray-900">
                    Total Commission: {formatAmount(totalAmount)}
                </div>
            </div>

            {/* Table with commission data */}
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        Date
                                    </th>
                                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        Activity Pool ID
                                    </th>
                                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        Currency
                                    </th>
                                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {commissions.map(commission => (
                                    <tr key={commission.id}>
                                        <td className="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                            {new Date(commission.createdAt).toLocaleString()} 
                                        </td>
                                        <td className="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                            {commission.activityPoolId}
                                        </td>
                                        <td className="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                            {commission.currencyCode}
                                        </td>
                                        <td className="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                            {formatAmount(commission.amount)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommissionList;
