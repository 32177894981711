
import { useState, useEffect } from 'react'
import { Field, Label, Switch } from '@headlessui/react'

export default function ToggleButton(props) {
  const [enabled, setEnabled] = useState(false);
  const label_default_value=props.label_default_value;
  const label_alternate_value=props.label_alternate_value;
  const setValue = props.set_value;
  const initial_value = props.initial_value;

    useEffect(() => {
        var initial_state = initial_value === label_alternate_value ? true : false;
        setEnabled(initial_state);
    }, [initial_value]);

  return (
    <Field className="flex items-center">
      <Switch
        checked={enabled}
        onChange={() => {
            setEnabled(!enabled)
            if(setValue) setValue(enabled ? label_default_value : label_alternate_value)
        }}
        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
      >
        <span
          aria-hidden="true"
          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
      </Switch>
      <Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{enabled ? label_alternate_value : label_default_value}</span>{' '}
      </Label>
    </Field>
  )
}
