import React, { useEffect, useState } from 'react';
import { UserProfileApi } from '../ApiConfig';
import { Link, useNavigate } from 'react-router-dom';

const UserProfileForm = (props) => {
    const userId = props.userId;
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState({
        weFitterPublicId: '',
        weFitterProfileToken: '',
        preferedName: '',
        lastName: '',
        nickname: '',
        avatar: '',
        dateOfBirth: '',
        biologicalSex: '',
        gender: '',
        countryCode: '',
        zipCode: '',
        town: '',
        ethnicity: '',
        mode: '',
        userId: '',
        profileImageUrl: '',
        height: {
            cm: '',
            ft: '',
            in: '',
            system: ''
        },
        weight: {
            st: '',
            lb: '',
            kg: '',
            system: ''
        }
    });

    useEffect(() => {
        if (userId) {
            const fetchUserProfile = async () => {
                const response = await UserProfileApi.get(`/user-profile/${userId}`);
                setUserProfile(response.data);
            };
            fetchUserProfile();
        }
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserProfile({ ...userProfile, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userProfile.userId) {
            await UserProfileApi.put(`/user-profile/${userId}`, userProfile);
        } else {
            await UserProfileApi.post('/user-profile/', userProfile);
        }
        navigate(-1);
    };

    return (
        userProfile.userId === ''
        ?
        <span>Please Wait ...</span>
        :
        <div>
            <form onSubmit={handleSubmit} class="mt-4">

                <div class="space-y-12">
                    <div class="border-b border-gray-900/10 pb-12">
                        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="sm:col-span-6 bg-black text-white px-2">
                                <h2 class="text-base font-semibold leading-7">User Profile (PrivyId: {userProfile.userId})</h2>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                                <div class="mt-2">
                                    <input type="text" name="lastName" value={userProfile.lastName} onChange={handleChange} placeholder="Last Name" id="lastName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="preferedName" class="block text-sm font-medium leading-6 text-gray-900">Preferred Name</label>
                                <div class="mt-2">
                                    <input type="text" name="preferedName" value={userProfile.preferedName} onChange={handleChange} placeholder="Preferred Name" id="preferedName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="nickname" class="block text-sm font-medium leading-6 text-gray-900">Nickname</label>
                                <div class="mt-2">
                                    <input type="text" name="nickname" value={userProfile.nickname} onChange={handleChange} placeholder="Nickname" id="nickName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="biologicalSex" class="block text-sm font-medium leading-6 text-gray-900">Biological Sex</label>
                                <div class="mt-2">
                                    <select id="biologicalSex" name="biologicalSex" value={userProfile.biologicalSex} onChange={handleChange} placeholder="Ethnicity" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                        <option></option>
                                        <option>MALE</option>
                                        <option>FEMALE</option>
                                        <option>OTHER</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender</label>
                                <div class="mt-2">
                                    <select id="gender" name="gender" value={userProfile.gender} onChange={handleChange} placeholder="Gender" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                        <option></option>
                                        <option>WOMAN</option>
                                        <option>MAN</option>
                                        <option>TRANSGENDER</option>
                                        <option>NON_BINARY</option>
                                        <option>PREFER_NOT_TO_SAY</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="dateOfBirth" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth</label>
                                <div class="mt-2">
                                    <input name="dateOfBirth" value={userProfile.dateOfBirth} onChange={handleChange} placeholder="Date of Birth" id="dateOfBirth" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="town" class="block text-sm font-medium leading-6 text-gray-900">Town / City</label>
                                <div class="mt-2">
                                    <input name="town" value={userProfile.town} onChange={handleChange} placeholder="Town / City" id="town" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="zipCode" class="block text-sm font-medium leading-6 text-gray-900">Zip / Post Code</label>
                                <div class="mt-2">
                                    <input name="zipCode" value={userProfile.zipCode} onChange={handleChange} placeholder="Zip / Post Code" id="zipCode" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="countryCode" class="block text-sm font-medium leading-6 text-gray-900">Country Code</label>
                                <div class="mt-2">
                                    <input name="countryCode" value={userProfile.countryCode} onChange={handleChange} placeholder="Country Code" id="countryCode" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="ethnicity" class="block text-sm font-medium leading-6 text-gray-900">Ethnicity</label>
                                <div class="mt-2">
                                    <select id="ethnicity" name="ethnicity" value={userProfile.ethnicity} onChange={handleChange} placeholder="Ethnicity" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                        <option></option>
                                        <option>WHITE</option>
                                        <option>HISPANIC</option>
                                        <option>ASIAN</option>
                                        <option>NATIVE</option>
                                        <option>PACIFIC</option>
                                        <option>AFRICAN</option>
                                        <option>MIXED</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="avatar" class="block text-sm font-medium leading-6 text-gray-900">Avatar</label>
                                <div class="mt-2">
                                    <input name="avatar" value={userProfile.avatar} onChange={handleChange} placeholder="Avatar" id="avatar" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="profileImageUrl" class="block text-sm font-medium leading-6 text-gray-900">Profile Image Url</label>
                                <div class="mt-2">
                                    <input name="profileImageUrl" value={userProfile.profileImageUrl} onChange={handleChange} placeholder="Profile Image Url" id="profileImageUrl" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <Link onClick={() => navigate(-1)} class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                    <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                </div>
            </form>

            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-8">
                <div class="sm:col-span-6 bg-black text-white px-2">
                    <h2 class="text-base font-semibold leading-7">Height and Weight</h2>
                </div>
                <div class="px-4 py-5 sm:p-6">
                    {
                        userProfile.height
                        ?
                        <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">Height: {userProfile.height.system === "IMPERIAL" ? userProfile.height.ft + 'ft' + userProfile.height.in + 'in' : userProfile.height.cm + 'cm'}  ( {userProfile.height.system} )</h3>
                        :
                        <></>
                    }
                    {
                        userProfile.weight
                        ?
                        <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">Weight: {userProfile.weight.system === "IMPERIAL" ? userProfile.weight.st + 'st' + userProfile.weight.lb + 'lbs' : userProfile.weight.kg + 'kg'}  ( {userProfile.height.system} )</h3>
                        :
                        <></>
                    }
                </div>
            </div>


            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-8">
            <div class="sm:col-span-6 bg-black text-white px-2">
                    <h2 class="text-base font-semibold leading-7">WeFitter</h2>
                </div>
                <div class="px-4 py-5 sm:p-6">
                    <h3 class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">WeFitter PublicId: {userProfile.weFitterPublicId}</h3>
                    <span class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2">WeFitter Profile Token: </span>
                    <textarea class="text-base font-semibold leading-7 text-gray-900 text-xs mt-2 w-full p-2" readOnly value={userProfile.weFitterProfileToken}>
                    </textarea>
                </div>
            </div>

        </div>

    );
};

export default UserProfileForm;