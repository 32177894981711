import React, { useState } from 'react';
import AddSponsorPrizePopup from './AddSponsorPrizePopup';
import { PaymentApi } from '../ApiConfig';

const AddSponsorPrizeButton = (props) => {

    var activity_pool = props.activity_pool;
    var setParentProgressMessage = props.setProgressMessages;
    const [progressMsg, setProgressMsg] = useState();
    const [showPopup, setShowPopup] = useState(false);


    const addProgressMessage = (msg) => {
        if (setParentProgressMessage) setParentProgressMessage(msg);
        else setProgressMsg(msg);
    }

    //Popup a prompt to get the Amount and Description
    const get_prize_details = () => {
        setShowPopup(true);
    }

    //Setup payment for supplied sponsor prize
    const createSponsorPrize = (prize_details) => {
        addPrize(prize_details);
    }

    const addPrize = async (prize_details) => {
        try {

            if (!activity_pool) {
                setProgressMsg('No activityPool supplied to add sponsor prize to');
            }
            else {
                try {
                    //Need to go through payment to add sponsor prize
                    var paymentRequest = {
                        "product": {
                            "type": "ACTIVITY_POOL_FUNDING",
                            "activity_pool_id": activity_pool.activityPoolId,
                            "reward_type": "BONUS_PRIZE",
                            "amount": prize_details.amount,
                            "description": prize_details.description,
                            "currency_code": activity_pool.currencyCode
                        },
                        "returnUrl": `https://everest.healthblocks.ai/paymentcomplete`
                    };
                    addProgressMessage('Requesting payment ... please wait');
                    var response = await PaymentApi.post('https://payment-api.healthblocks.ai/transactions/', paymentRequest);
                    //var response = await PaymentApi.post('https://dev-payment-api.healthblocks.ai/transactions/', paymentRequest);
                    //var response = await PaymentApi.post('http://localhost:8000/transactions/', paymentRequest);
                    var makePaymentUrl = response.data.redirect_url;
                    addProgressMessage(`Redirecting to payment (${makePaymentUrl})... please wait`);
                    window.location.href = makePaymentUrl;
                }
                catch (err) {
                    addProgressMessage(`Error Requesting Payment ${JSON.stringify(err.message)}`);
                }

            }

        }
        catch (err) {
            setProgressMsg(`Unexpected error adding sponsored prize to activity pool - ${err.message}`);
        }
    };

    return (
        activity_pool.activityPoolStatus === "REVIEW" || activity_pool.activityPoolStatus === "DRAFT"
            ?
            <section>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    <button type="button" onClick={() => get_prize_details()} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add Sponsor Prize</button>
                </div>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    {
                        progressMsg
                            ?
                            <div>
                                <span class="block text-xs font-medium leading-6 text-gray-400">{progressMsg}</span>
                            </div >
                            :
                            <span></span>
                    }
                </div>

                <AddSponsorPrizePopup show_popup={showPopup} set_show_popup={setShowPopup} set_sponsor_prize={createSponsorPrize} />
            </section >
            :
            <section>Unable to add a sponsor prize</section>


    );
};

export default AddSponsorPrizeButton;