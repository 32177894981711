import React from 'react';

const CopyToClipboard = ({ value }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value)
      .then(() => {
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
      <img src="/copy-outline-icon.png" alt="Copy" width="20" height="20" />
    </div>
  );
};

export default CopyToClipboard;
